@import '../../../../scss/theme-bootstrap';

$grid-row-gutter: 40px;

.grid-row-formatter {
  position: relative;
  width: 100%;
  &__wrapper {
    width: initial;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    text-align: $ldirection;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    &.padding-default-wrapper {
      padding: 0;
      @include breakpoint($landscape-up) {
        padding: 0 20px;
      }
    }
    .padding-default-header {
      padding: 30px 20px 10px;
      @include breakpoint($landscape-up) {
        padding: 40px 20px 0;
      }
    }
    &.grid-row-formatter--standard-margin {
      .padding-default-header {
        padding: 0 0 40px;
      }
    }
  }
  &__grid {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @include breakpoint($landscape-up) {
      flex-direction: row;
    }
    .grid-row-formatter__wrapper--mobile-2-column & {
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      @include breakpoint($landscape-up) {
        justify-content: center;
        flex-wrap: nowrap;
      }
    }
    &-item {
      width: 100%;
      .grid-row-formatter__wrapper--mobile-2-column & {
        width: calc(50% - (#{$grid-row-gutter} / 2));
        @include breakpoint($landscape-up) {
          width: 100%;
        }
      }
      &--zero-margin {
        .grid-row-formatter__wrapper--mobile-2-column & {
          width: 50%;
          @include breakpoint($landscape-up) {
            width: 100%;
          }
        }
      }
      &.padding--bare {
        padding: 30px 20px;
        @include breakpoint($medium-up) {
          padding: 40px 20px;
        }
        .tout-block__wrapper-pad-default {
          padding-bottom: 0;
        }
        .tout-block__content-above-media-wrapper {
          padding-top: 0;
        }
      }
    }
    &-item:not(:last-of-type):not(.grid-row-formatter__grid-item--zero-margin) {
      margin-bottom: 20px;
      @include breakpoint($landscape-up) {
        margin-bottom: 0;
        margin-#{$rdirection}: $grid-row-gutter;
      }
    }
  }
  &__header-content {
    display: inline-block;
  }
  &--standard-margin {
    padding: 30px $grid-row-gutter / 2 0;
    @include breakpoint($landscape-up) {
      padding: 40px $grid-row-gutter 0;
    }
    .tout-block__content-above-media-wrapper {
      padding-top: 24px;
      @include breakpoint($landscape-up) {
        padding-top: 0;
      }
    }
    .grid-row-formatter__grid-item:first-child {
      .tout-block__content-above-media-wrapper {
        padding-top: 0;
      }
    }
    &.grid-row-formatter__wrapper--mobile-2-column {
      .grid-row-formatter__grid-item:nth-child(-n+2) {
        .tout-block__content-above-media-wrapper {
          padding-top: 0;
        }
      }
    }
    .tout-block__wrapper-pad-default {
      padding-bottom: 20px;
      @include breakpoint($landscape-up) {
        padding-bottom: 24px;
      }
    }
  }
  .content-block__line p {
    line-height: inherit;
  }
  .no-background {
    background-color: transparent;
  }
  &__grid-accordion {
    &:not(.grid-items-initialized) {
      .grid-row-formatter__grid-item {
        &:nth-child(n + 4) {
          display: none;
          @include breakpoint($landscape-up) {
            display: block;
          }
        }
      }
    }
    .grid-row-formatter__grid-item {
      transition: max-height 0.3s ease-in;
      overflow: hidden;
      margin-bottom: 0;
      &:not(:last-of-type):not(.grid-row-formatter__grid-item--zero-margin) {
        margin-bottom: 0;
      }
      &.grid-item-hidden {
        padding: 0;
        opacity: 0;
        visibility: hidden;
        max-height: 0;
      }
      &.grid-item-shown {
        opacity: 1;
        visibility: visible;
        max-height: 1000px;
      }
    }
    &.all-grids-shown {
      + .grid-row-formatter__see-all-grids {
        .see_all_rows {
          display: none;
        }
        .see_less_rows {
          display: block;
        }
      }
    }
  }
  .see_all_rows {
    display: block;
  }
  .see_less_rows {
    display: none;
  }
  &__see-all-grids {
    width: 100%;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__see-all-btn {
    width: 100%;
    text-align: center;
    display: none;
  }
  &__borders-pc {
    .grid-row-formatter__grid-item {
      @include breakpoint($landscape-up) {
        border-#{$ldirection}: 1px solid $color-gray-op15;
      }
      &:first-child {
        border-color: transparent;
      }
      .dark-background & {
        border-color: $color-white-op15;
        &:first-child {
          border-color: transparent;
        }
      }
      &.padding--bare {
        padding: 30px 20px;
        @include breakpoint($medium-up) {
          padding: 40px 20px 0;
        }
      }
    }
  }
}
